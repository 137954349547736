<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="appLogo" max-height="70px" max-width="200px" alt="logo" contain class="me-3"></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Adventure starts here 🚀</p>
          <!-- <p class="mb-2">
            Make your app management easy and fun!
          </p> -->
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="registerForm" @submit="createUser">
            <v-text-field
              v-model="firstName"
              outlined
              label="First Name"
              :rules="[validators.required]"
              placeholder="First Name"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="lastName"
              outlined
              label="Last Name"
              :rules="[validators.required]"
              placeholder="Last Name"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="email"
              outlined
              label="Email"
              :rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              label="Password"
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :rules="[validators.required, validators.passwordValidator]"
              hide-details="auto"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-checkbox v-model="agree" hide-details class="mt-1" :rules="[validators.checked]">
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn type="submit" block color="primary" class="mt-6" @click.prevent="createUser" :disabled="loading">
              Sign Up
              <v-progress-circular v-if="loading" indeterminate color="white"></v-progress-circular>
            </v-btn>
          </v-form>
        </v-card-text>

        <v-alert v-if="message || errors.length > 0" class="mt-4" color="error" text>
          <p class="font-weight-semibold mb-1">Messages</p>
          <p v-if="message" class="text-sm mb-0">
            {{ message }}
          </p>
          <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
            {{ error }}
          </p>
        </v-alert>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> Already have an account? </span>
          <router-link :to="{ name: 'auth-login-v1' }"> Sign in instead </router-link>
        </v-card-text>

        <!-- divider -->
        <!-- <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social link -->
        <!-- <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { useRouter } from '@core/utils'
import { required, emailValidator, passwordValidator, checked } from '@core/utils/validation'
import { mapActions, mapGetters } from 'vuex'
import axios from '@axios'

export default {
  data() {
    return {
      email: '',
      name: '',
      password: '',
      firstName: '',
      lastName: '',
      agree: false,
      loading: false,

      // errors
      message: '',
      errors: [],
    }
  },
  methods: {
    ...mapActions({
      getUser: 'auth/getUser',
    }),
    async createUser(e) {
      e.preventDefault()
      this.loading = true

      const isFormValid = this.$refs.registerForm.validate()
      if (!isFormValid) {
        this.loading = false
        return
      }

      const resp = await axios
        .post('auth/register', {
          email: this.email,
          name: this.name,
          password: this.password,
          password_confirmation: this.password,
          client_id: 'PA0001',
          last_name: this.lastName,
          first_name: this.firstName,
        })
        .then(response => {
          console.log('updated')
          console.log(response.data)
          this.login()
        })
        .catch(error => {
          console.log(error)
          this.message = error.message
        })
        .finally(() => {
          this.loading = false
        })
    },
    login() {
      axios
        .post('/auth/login', { email: this.email, password: this.password })
        .then(response => {
          const { accessToken, user } = response.data

          if (user) {
            localStorage.setItem('accessToken', accessToken)
            localStorage.setItem('userData', JSON.stringify(user))
            this.getUserById()
          }
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getUserById() {
      await this.getUser().then(response => {
        console.log(response.data)
        localStorage.setItem('userProfile', JSON.stringify(response.data.profile))
        this.$router.push({ name: 'dashboard' })
      })
    },
  },
  setup() {
    const registerForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)
    const usernameref = ref('')
    const emailref = ref('')
    const passwordref = ref('')
    const agreeref = ref(false)
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      usernameref,
      emailref,
      passwordref,
      agreeref,
      errorMessages,
      socialLink,
      validators: {
        required,
        emailValidator,
        passwordValidator,
        checked,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      registerForm,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
